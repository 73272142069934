'use strict'


// run
document.body.classList.add('js');


// top screen sizer
$(function(){
	var t = $('.top-wrapper');
	//t.height($(window).height() - 30);
	function tr() {
		t.css('min-height',$(window).height() - 30);
	}
	tr();
	$(window).resize(function() {
		tr();
	});
});


// about slider
$(function () {
	var s = $('#a-slider').bxSlider({
		controls: 0,
		pager: 1,
		adaptiveHeight: 1,
		pagerCustom: '#a-slider-tab',
		mode: 'fade',
		wrapperClass: 'a-slider-wrap',
		touchEnabled: 0
	});
});


// Sticky menu
/*
$(window).scroll(function () {
    if ($(this).scrollTop() > 150){
        $('#cart').addClass('sticky');
    } else {
        $('#cart').removeClass('sticky');
    }
});
*/

// Product amount
/*
function amountChanger(btn) {
	var a = $(btn).parent().find('.pa-amount');
	var n = a.text();

	if ($(btn).hasClass('pa-mns') && n > 1) {
		n--;
		$(a).text(n);
		return;
	}
	if ($(btn).hasClass('pa-pls') && n <= 99) {
		n++;
		$(a).text(n);
		return;
	}
	return;
}
*/

// Add to cart
/*
function addToCart(p) {
	var pName 		= $(p).find('.p-ttl').text(); 				// Название теплицы
	var pMeters		= $(p).find('.cl-i.active').text(); 		// Метраж
	var pCost		= $(p).find('.pc-amount').text(); 			// Стоимость комплектации
	var pAmount		= $(p).find('.pa-amount').text(); 			// Количество
	var pTotal = number_format(pCost * pAmount, 0, '.', ' '); 	// Общая стоимость позиции
	var pGift		= $(p).find('.gift span').text();			// Подарок (Если есть)
	var pComplect	= $(p).find('input:checked');				// Комплектация
	var pComplHTML	= '';										// Контейнер для комплектации
	
	// Добавляем подарок к комлектации, если есть
	if (pGift) {
		pComplHTML = '<div class="cp-gift"><i class="i-gift"></i>' + pGift + ' в подарок</div>'
	}
	
	// Перебираем опции и вставляем в общий список
	$(pComplect).each(function(){
		c =  $(this).nextAll('span').text();
		pComplHTML = pComplHTML + '<div>' + c + '</div>';
	});
	
	var tmp = '<div class="c-product"><div class="cp-header"><div class="cp-ttl"><span class="name">' + pName + ' ' + pMeters + ' м</span> <span class="gold">•</span> <span class="cost">' + pCost + '</span> <span class="rub">руб</span></div><div class="cp-cost-box"><div class="p-amount"><span class="pa-btn pa-mns"></span><span class="pa-amount">' + pAmount + '</span><span class="pa-btn pa-pls"></span></div><div class="flex-line"><div class="bold"><span class="cpf-cost">' + pTotal + '</span> <span class="rub">руб</span></div><div class="cp-del i-bin"></div></div></div></div><div class="cp-complectation">' + pComplHTML + '</div></div>';

	$('#c-products').append(tmp); // Добавим шаблон с переменными в корзину
	$(p).find('.add').addClass('added').text('Добавлено'); // Изменим кнопку для наглядности
	totalCartCalculate(); // Пересчитаем корзину

}
*/

// Products lisener
(function() {
	$('.products').on('click', function (e) {
		//e.preventDefault();
		var t = e.target;
		var p = $(t).parents('.product');

		// Добавили в корзину
		/*
		if ($(t).hasClass('add')) {
			if ( $(t).hasClass('added') ) {
				return;
			} else {
				addToCart(p);
			}
			return;
		}
		*/
		
		// Длина
		if ($(t).hasClass('cl-i')) { 
			var c = $(t).text();
			$(t).parent().children('.cl-i').removeClass('active');
			$(t).addClass('active');
			prodCalc(p);
			btnReload();
			return;
		}
		
		// Количество
		/*
		if ($(t).hasClass('pa-btn')) {
			amountChanger(t);
			btnReload();
			return;
		}
		*/

		// Опции 
		if ($(t).is('input')) {
			prodCalc(p);
			btnReload();
			return;
		}
		
		// Инфо
		if ($(t).hasClass('info')) {
			$(t).tooltipster('open');
			return;
		}
		
		// Изменить кнопку в дефолт
		function btnReload(){
			var btn = $(p).find('.add');
			if ( $(btn).hasClass('added') ) {
				$(btn).removeClass('added').text('В корзину'); 
			}
		}
		
		return;
	});
})();


// equipment calculation
function prodCalc(p) {
	var price 	= $(p).find('.pc-amount');						// Объект с ценой
	var meters	= $(p).find('.cl-i.active').text(); 			// Выбранный метраж							
	var pCost	= $(p).find('.p-cost'); 						// Блок с ценой							
	var base	= baseCalc(); 									// Стоимость базы
	//var light	= extracter('light',':checked');				// Стоимость базы
	var option	= extracter('option',':checked');				// Чекнутая опция
	var total	= totaler();									// Финальная стоимость
	var oldP 	= $(p).find('.old-o-cost');						// Старая цена
	var salePercent = $(p).attr('data-sale'); 					// Извлекаем процент

	// Извлечение варианта 
	function extracter(str,check) {
		var str = 'data-' + str;								// Формируем имя атрибута
		var obj = $(p).find('[' + str + ']' + check);			// Извлекаем строку со значениями
		if (obj.is(':empty') || obj.is('div') ) {				// Не пусто-лй объект или дивный
			obj = cutter( obj.attr(str) );						// Отправляем строку в нарезку
		} else {
			obj = 0;
		}
		function cutter(s) {
			s = s.split(meters + '=')[1]; 						// Отрезаем до условия
			s = s.split(' ')[0]; 								// Отрезаем после условия
			return s;
		}
		return +obj;
	}
	
	// Получаем стоимость базы
	function baseCalc() {
		var b	= $(p).attr('data-start'); 						// Базовая стоимость
		var m 	= extracter('base','');							// Получаем значение по длинне
		return 	+b + +m;										// Суммируем с базовой стоимостью и отдаем
		
	}
	
	// Финальная стоимость
	function totaler() {
		//var t = base 	- light;					// Вычитаем из базы процент облегченки
		var	t = base + option;					// Плюсуем процент за опцию
		return t;
	}

	// Привлечем внимание
	function priceAttentionP() {
		$(pCost).addClass('c-attention-p');							// Добавляем анимацию
		setTimeout(function () {
			$(pCost).removeClass('c-attention-p');	
		}, 100);
	}
	
	function priceAttentionM() {
		$(pCost).addClass('c-attention-m');							// Добавляем анимацию
		setTimeout(function () {
			$(pCost).removeClass('c-attention-m');	
		}, 100);
	}
	
	var cPrice	= $(price).text();								// Текущая цена
		cPrice 	= cPrice.replace(/\s+/g, '');					// Удалить пробел из строки
		cPrice	= +cPrice;
	
	var nPrice 	= Math.round(total);							// Новая цена

	if (cPrice !== nPrice ) {				// Больше ноля
		
		var step = 10;							// Количество шагов
		var speed = 30;
		
		// В зависимости от разницы новой / старой цены, убавляем количество шагов для последующего прибавления в анимации, дополнительно анимируем CSS
		
		var aPrice = null; // Переменная, которая участвует в анимации
		
		if ( cPrice > nPrice  ) {
			aPrice = nPrice + step - 1;
			priceAttentionM();
		} 
		if ( cPrice < nPrice  ) {
			aPrice = nPrice - step + 1;
			priceAttentionP();
		}
		
		// В каждой итерации увеличиваем на единицу, пока не достингем числа шагов.
		(function loop(i) {
			setTimeout(function () {
				if ( cPrice > nPrice ) {		// Разнице больше ноля
					$(price).text( number_format(aPrice, 0, '.', ' ') );
					aPrice = --aPrice;
				};
				
				if ( cPrice < nPrice ) {		// Разнице меньше ноля
					$(price).text( number_format(aPrice, 0, '.', ' ') );
					aPrice = ++aPrice;
				};

				if (--i) loop(i);
				
				speed = +speed + 15;
			}, +speed );
		})( step );
	}

	setTimeout(function () {
		substituter(oldP, salePercent);  // Считаем старую цену и подставляем
	}, 1000);

}


// Beautifulizer of numbers
function number_format(number, decimals, dec_point, thousands_sep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k)
        .toFixed(prec);
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
    .split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '')
    .length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1)
      .join('0');
  }
  return s.join(dec);
}

// Комплектует заказ и отправляет на сервер
/*
function orderSender() {
	var antiSpam = $('#cart-transfer').find('input[name=ar]').val()
	var phone = $('#phone').val();
	
	var orderList = 'ar=' + antiSpam + '&' +
					'phone=' + phone + '&' +
					'order=';
	
	// Стоимость, доставка, количество:
	var whole		= $('#total-no-del-cost').text(); 		// Цена без доставки
	var delivery	= $('.order-wrap .delivery input:checked').nextAll('span').text(); // Доставка
	var final		= $('#final-cost').text(); 				// Финальная цена с доставкой
	var comAmount	= $('#c-products .pa-amount');
	
	// Пересчитаем общее количество
	var comAmountСount = 0;									// Количество теплиц в заказе
	comAmount.each(function(i, e){
		var a = $(e).text();
		comAmountСount = +comAmountСount + +a;
	})
	
	// Вставляем общие цифры в лист заказа
	orderList += 	'Стоимость: ' + whole + '\n' +
					'Доставка: ' + delivery + '\n' +
					'Стоимость с доставкой: ' + final + '\n' +
					'Товаров в заказе: ' + comAmountСount + '\n\n';
				   
	// Переберем все продукты в корзине и выдернем из них нужные поля в переменную
	$('.c-product').each(function(i, e){
		var name 		= $(e).find('.name').text();
		var cost 		= $(e).find('.cost').text();
		var amount 		= $(e).find('.pa-amount').text();
		var options		= $(e).find('.cp-complectation div');
		var total 		= $(e).find('.cpf-cost').text();
		
		// Переберем каждую опцию и запишем ее в строку
		var optionsFormated = '';
		if (options) {
			options.each(function(i, opt){
				var o = $(opt).text();
				optionsFormated = optionsFormated + o + ', ';
			});
		}
		
		// Отрежем последнюю запятую с пробелом
		optionsFormated = optionsFormated.slice(0, -2);
		
		// Склеим все в строку
		var prod = 	'Позиция ' 			+ ++i + '\n' +
					'Наименование: ' 	+ name + 
					'\nСтоимость: ' 	+ cost + 
					'\nКоличество: ' 	+ amount +
					'\nОпции: ' 		+ optionsFormated +
					'\nВ сумме: '	 	+ total +
					'\n\n';
		
		orderList = orderList + prod;
		
	});
	
	// console.log(orderList);

	$.ajax({
		type: "POST",
		url: "assets/php/handler.php",
		data: orderList,
		success: function (msg) {
			
			var result = '';
			var message = $('#cart-message');
			
			if (msg == 'OK') {

				result = '<h2 class="no-split mb">Заказ оформлен</h2><p>Мы вам позвоним в ближайшее время для подтверждения заказа. Работаем с 9⁰⁰ до 21⁰⁰.</p>';

				// Опустошим корзину, покажем сообщения
				message.html(result);
				$('#c-products').empty();
				$('#cart').addClass('empty').removeClass('filled');
				$('.cart-list').addClass('empty').removeClass('filled');
				
				// скрываем корзину
				setTimeout(function(){
					$('#cart-list-wrapper').fadeOut(200);
					$('body').css('overflow', 'auto');
				}, 3000);

			} else {
				result = msg;
				message.html(result);
			}
		}
	});
	
}
*/

// Cart lisener
/*
(function() {
	var cartWrapper = $('#cart-list-wrapper');
	
	// Клик на кнопке корзины
	$('#cart').click(function(){
		if ( $(this).hasClass('filled') ) {
			cartWrapper.fadeIn(200);
			$('body').css('overflow', 'hidden');
		}
	});
	
	// Обработчик для событий клика в корзине
	cartWrapper.on('click', function (e) {
		//e.preventDefault();
		var t = e.target;

		// Оформили заказ
		if ($(t).hasClass('cart-btn')) {
			event.preventDefault();
			orderSender();
			return;
		}
		
		// Количество
		if ($(t).hasClass('pa-btn')) {
			amountChanger(t);										// Обработаем изменение количества
			var prod	= $(t).parents('.cp-header'); 				// Основной объект с переменными
			var cost 	= prod.find('.cost').text(); 				// Извлекаем стоимость продукта
			var amount	= prod.find('.pa-amount').text(); 			// Извлекаем количество
			var pTotal 	= number_format(cost * amount, 0, '.', ' ');// Перемножаем и делаем красиво
			prod.find('.cpf-cost').text( pTotal );					// Выводим в ячейку финальной цены
			totalCartCalculate();
			return;
		}
		
		// Изменение доставки
		if ($(t).is('input[name=delivery]')) {
			totalCartCalculate();
			return;
		}
		
		// Удаление позиции
		if ($(t).hasClass('cp-del')) {
			var p = $(t).parents('.c-product');
			p.slideUp(400);
			
			setTimeout(function(){
				p.remove();
			}, 400);
			
			setTimeout(function(){
				totalCartCalculate();
			}, 500);
		}
		
		// Нажали на крестик
		if ($(t).hasClass('c-close')) {
			cartClose()
		}
		
		// Нажали на белое поле вокруг слоя корзины
		if (t == this) {
			cartClose();
		}
		
		// Закрыватель окна
		function cartClose() {
			cartWrapper.fadeOut(200);
			$('body').css('overflow', 'auto');
		}
		
		// Кликнули на поле телефона
		if ($(t).is('#phone')) {
			var parent = $(t).parent();
			parent.removeClass('error');
			if ( parent.next().hasClass('input-error') ) {
				parent.next('.input-error').remove();
			}
			return;
		}
		
		//console.log( $(t) );
		return;
	});
})();
*/

// Total cart calculate
/*
function totalCartCalculate() {
	var cartObj 	= $('.cart-list');						// Объект корзины
	var prods		= cartObj.find('.c-product');			// Массив с продуктами
	var amounts		= '';									// Переменная для общего количества
	var subtotal	= '';									// Переменная для общей стоимости без доставки
	var total		= '';									// Пероеменная для финальной стоимости
	var delivery	= '';									// Стоимость доставки
//	var pCount		= '';									// Количество позиций в корзине
	
	// Пребираем все продукты и инкреминируем переменные
	$(prods).each(function(i, e){
		var a = $(e).find('.pa-amount').text();
		var s = $(e).find('.cpf-cost').text();
			s = s.replace(/\s+/g, '');
		
//		pCount = +pCount + 1;
		amounts = +amounts + +a ;
		subtotal = +subtotal + +s ;
	});

	// Подставим общее количество позиций в кнопку корзины
//	$('#c-prod-count').text(pCount);
	$('#c-prod-count').text(amounts);
	
	// Считаем доставку и подставляем в варианты
//	$('.order-wrap .delivery .label-wrap').each(function(i, e){
//		var c = $(e).find('input').attr('data-delivery'); // Вынимаем стоимость доставки
//		var tc = +amounts * +c; // Перемножим стоимость с общей стоимостью доставки
//		$(e).find('.delCost').text(tc); // Подставляем в поле
//	})
	
	var dCost = cartObj.find('.delivery input:checked').attr('data-delivery'); // Выбранный кост доставки
		//dCost = +dCost * +amounts;
	
	// Считаем финальную стоимость
	total = +dCost + +subtotal;
	
	// Делаем красивой цену без доставки
	subtotal = number_format(subtotal, 0, '.', ' ');
	
	// Выведем стоимость без доставки в карточке корзины
	cartObj.find('#total-no-del-cost').text(subtotal);
	
	// Подставим стоимость без доставки в кнопку корзины
	$('#cart-cost').text(subtotal);
	
	// Отдаем финальную стоимость с доставкой пользователю
	cartObj.find('#final-cost').text( number_format(total, 0, '.', ' ') );
	
	// Проверим, не пуста-ли корзина и отобразим состояния
	if (subtotal == 0) {
		// Корзина пуста, покажем сообщения и скроем корзину (если открыта)
		$('#cart-message').text('Корзина пуста :-(');
		$('#cart').addClass('empty').removeClass('filled');
		cartObj.addClass('empty').removeClass('filled');
		setTimeout(function(){
			$('#cart-list-wrapper:visible').fadeOut(200);
			$('body').css('overflow', 'auto');
		},2000);
		
	} else {
		// Корзина не пуста, покажем цифры
		$('#cart').addClass('filled').removeClass('empty');
		cartObj.addClass('filled').removeClass('empty');
		
	}
	
}
*/

// form

// Form lisener
(function() {
	var wrapper = $('.form-wrapper');
	var message = wrapper.find('.form-message');
	var formBody = wrapper.find('.form-body');

	// Обработчик кликов
	wrapper.on('click', function (e) {
		var t = e.target;
		
		// Нажали на крестик
		if ($(t).hasClass('c-close')) {
			closer();
			return;
		}
		
		// Нажали на белое поле вокруг слоя корзины
		if (t == this) {
			closer();
			return;
		}
		
		// Оформили заказ
		if ($(t).hasClass('form-btn')) {
			event.preventDefault();
			handler();
			return;
		}
		
		return;
	});
	
	// Клик на кнопке вызова
	$('.form-opener').click(function(){
		formBody.show();
		message.empty();
		$('.error').removeClass('error');
		$('.input-error').remove();
		wrapper.find("input[type=text], textarea").val('');
		wrapper.fadeIn(200);
		$('body').css('overflow', 'hidden');
	});
	
	// Виндоу клосер
	function closer() {
		wrapper.fadeOut(200);
		$('body').css('overflow', 'auto');
	}
	
	function handler() {
		var formdata = 'phone=' + $('#form-phone').val();
		
		$.ajax({
			type: "POST",
			url: "assets/php/form.php",
			data: formdata,
			success: function (msg) {

				var result = '';

				if (msg == 'OK') {

					result = '<h3>Запрос оставлен</h2><p>Мы вам позвоним в ближайшее рабочее время. Работаем с 9⁰⁰ до 21⁰⁰.</p>';

					// Покажем сообщение
					formBody.hide();
					message.html(result);

					// скрываем корзину
					setTimeout(function(){
						 closer();
					}, 5000);

				} else {
					result = msg;
					message.html(result);
				}
			}
		});
	}
	
})();

// Smooth anchor link
$('body').on('click', '[href*="#"]', function(e){
  var fixed_offset = 50;
  $('html,body').stop().animate({
	  scrollTop: $(this.hash).offset().top - fixed_offset
  
  }, {
	 duration: 1000,
	 easing: 'swing'
  });
  e.preventDefault();
});

// Menu
(function(){
	var menuIcon = $('.top-menu-btn');
	var menu = $('.top-links');
	var body = $('body');
	var cChecher = null;
	
	body.on('touchstart  mousedown', function (e) {
		var t = e.target;
		
		if ($(t).hasClass('top-menu-btn')) {
			mOpener();
		};
		
		if ($(t).hasClass('menu-close-btn')) {
			mHider();
		};
		
		if ( $(t).is('.top-links a') ) {
			if (  $(window).width() < 800 ) {
				mHider();
			}
		};
		
		if( $(t).is(":not(.top-links):not(.top-menu-btn)") ) {
			if (  $(window).width() < 800 ) {
				mHider();	
			}
		}
		
	});
	
//	function clickChecker() {
//		$(document).on('touchstart  mousedown', function (event) {
//			if ( menu.is(':visible') ) {
//				if (event.type == 'touchstart') {
//					var touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
//					hiderChecker(event);
//				} else {
//					hiderChecker(event);
//				}
//				event.stopPropagation();
//			}
//		});
//		function hiderChecker(e) {
//			if (!menu.is(e.target) && menu.has(e.target).length === 0) {
//				mHider();
//			}
//		}
//	};
	
	function mOpener(){
		$(menu).fadeIn(200).addClass('showed');
		$(body).addClass('scroll-off');
	};
	
	function mHider(){
		$(menu).fadeOut(200);
		setTimeout(function(){
			menu.removeClass('showed');
		},200);
		
		$(body).removeClass('scroll-off');
	};
	
//	$(window).scroll(function () {
//		if (  $(window).width() < 801 ) {
//			if ($(this).scrollTop() > 200){
//			if(!menuIcon.hasClass('sticky-menu')) {
//				menuIcon.hide();
//				setTimeout(function(){
//					menuIcon
//						.fadeIn(200)
//						.addClass('sticky-menu');
//				},0);
//			}
//
//			} else {
//				if(menuIcon.hasClass('sticky-menu')) {
//					menuIcon.fadeOut(200);
//					setTimeout(function(){
//						menuIcon
//							.fadeIn(200)
//							.removeClass('sticky-menu');
//					},200);
//				}
//			}
//			
//		}
//	});
	
	$(window).resize(function() {
		if (  $(window).width() > 800 ) {
			menu.show();
		} else {
			menu.hide();
		}
	});
	
})();

// One click btn
(function(){
	var oneClickBtn  = $('.one-click');
		$(window).scroll(function () {
			if ( $(this).scrollTop() > 700 ){
				oneClickBtn.fadeIn(200);
			} else {
				oneClickBtn.fadeOut(200);
			}
		})
})();

// Substituter old price
function substituter(element, percent) {
	var currentPrice = $(element).next('.p-cost').find('.pc-amount').text(); // выберем стоимость
	currentPrice = currentPrice.replace(/\s+/g, ''); // удалим пробелы

	var percent = (currentPrice / 100) * percent; // Найдем процент от суммы
	var oldPrice = +currentPrice + +percent; // Сумируем старую сумму и процент

	$(element).find('.old-pc-amount').text( number_format(oldPrice, 0, '.', ' ') ); // Делаем красиво и подставляем
}

// Substituter for all produts
(function(){
	$('.product[data-sale]').each(function(indx, element){
		var salePercent = $(element).attr('data-sale');  // Извлекаем процент
		var oldP 		= $(element).find('.old-o-cost');// Старая цена
		
		substituter(oldP, salePercent);
	});
})();